export default {
  loginFormValidations: {
    username: [
      {
        validation: "notEmpty",
        message: "Messages.usernameEmpty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Messages.minLength",
      },
      {
        validation: "email",
        message: " Messages.email",
      },
    ],
    endDate: [
      {
        validation: "notEmpty",
        // compareTo:''
        message: "Messages.passwordEmpty",
      },
    ],
  },
  InviteValidations: {
    firstName: [
      {
        validation: "notEmpty",
        message: "Firstname cannot be empty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Max length allowded is 70",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z-,]*$/,
        message: "Firstname should contain only alphabets",
      },
    ],
    lastName: [
      {
        validation: "notEmpty",
        message: "Lastname cannot be empty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Max length allowded is 70",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z-,]*$/,
        message: "Lastname should contain only alphabets",
      },
    ],
    email: [
      {
        validation: "notEmpty",
        message: "Email cannot be empty",
      },
      {
        validation: "email",
        message: "Please enter valid Email ID",
      },
    ],
    roleId: [
      {
        validation: "notEmpty",
        message: "Messages.roleIdEmpty",
      },
    ],
  },
  ProfileFormValidations: {
    firstName: [
      {
        validation: "notEmpty",
        message: "Messages.firstNameEmpty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Messages.maxLengthFirstName",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z-,]*$/,
        message: "Messages.onlyAlpbabetsMessage",
      },
    ],
    //middleName: [],
    lastName: [
      {
        validation: "notEmpty",
        message: "Messages.lastNameEmpty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Messages.maxLengthLastName",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z-,]*$/,
        message: "Messages.onlyAlpbabetsMessage",
      },
    ],
    email: [
      {
        validation: "notEmpty",
        message: "Messages.emailEmpty",
      },
      {
        validation: "email",
        message: "Messages.validEmail",
      },
    ],
    contactNumber: [
      {
        validation: "notEmpty",
        message: "Messages.mobileEmpty",
      },
      {
        validation: "regex",
        // regex: /^[0-9]{1,11}$/,
        regex: /^[0][1-9]\d{9}$|^[1-9]\d{10}$/,
        message: "Messages.mobileRegexMessage",
      },
    ],
    dateOfBirth: [
      {
        validation: "notEmptyForNull",
        message: "Date of birth cannot be empty",
      },
    ],
    occupation: [
      // {
      //   validation: "notEmpty",
      //   message: Messages.occupationEmpty,
      // },
      // {
      //   validation: "maxLength",
      //   maxLength: 70,
      //   message: Messages.maxLengthOccupation,
      // },
      // {
      //   validation: "regex",
      //   regex: /^[a-zA-z]+([\s][a-zA-Z]+)*$/,
      //   message: Messages.onlyAlpbabetsMessage,
      // },
      // {
      //   validation: "regex",
      //   regex: /^[a-zA-Z ]*$/,
      //   message: Messages.onlyAlpbabetsMessage,
      // },
    ],
    houseName: [
      {
        validation: "notEmpty",
        message: "Messages.houseEmpty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Messages.maxLengthHouseName",
      },
      // {
      //   validation: "regex",
      //   regex: /^[a-zA-z]+([\s][a-zA-Z]+)*$/,
      //   message: Messages.onlyAlpbabetsMessage,
      // },
      {
        validation: "regex",
        //regex: /^[A-Za-z0-9 ]+$/,
        regex: /^[^\s]+(\s+[^\s]+)*$/,
        message: "Messages.spaceNotAllowded",
      },
    ],
    street: [
      {
        validation: "notEmpty",
        message: "Messages.streetEmpty",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: " Messages.maxLengthStreet",
      },
      {
        validation: "regex",
        regex: /^[^\s]+(\s+[^\s]+)*$/,
        message: "Messages.spaceNotAllowded",
      },
    ],
    city: [
      {
        validation: "notEmpty",
        message: "Messages.cityEmpty",
      },
      {
        validation: "regex",
        regex: /^[^\s]+(\s+[^\s]+)*$/,
        message: "Messages.spaceNotAllowded",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z ]*$/,
        message: "Messages.onlyAlpbabetsMessage",
      },
    ],
    designation: [
      // {
      //   validation: "notEmpty",
      //   message: Messages.designationNameEmpty,
      // },
      // {
      //   validation: "maxLength",
      //   maxLength: 70,
      //   message: Messages.maxLengthdesignation,
      // },
      // {
      //   validation: "regex",
      //   regex: /^[a-zA-z]+([\s][a-zA-Z]+)*$/,
      //   message: Messages.onlyAlpbabetsMessage,
      // },
    ],
    county: [
      {
        validation: "notEmpty",
        message: "Messages.countyEmpty",
      },
    ],
    postCode: [
      {
        validation: "notEmpty",
        message: "Messages.postcodeEmpty",
      },
      {
        validation: "regex",
        regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: "Messages.postcodeAlphaNumeric",
      },
      {
        validation: "regex",
        regex: /^[^\s]+(\s+[^\s]+)*$/,
        message: " Messages.spaceNotAllowded",
      },
    ],
    agentJobRole: [
      // {
      //   validation: "notEmpty",
      //   message: Messages.agentJobRoleEmpty,
      // },
      // {
      //   validation: "maxLength",
      //   maxLength: 70,
      //   message: Messages.maxLengthAgentJobRole,
      // },
    ],
    agentFirmName: [
      {
        validation: "maxLength",
        maxLength: 70,
        message: " Messages.maxLengthAgentFirm",
      },
    ],
  },
  createProjectValidation: {
    projectName: [
      {
        validation: "notEmpty",
        message: "Project name cannot be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Project name min length is 2",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Project name max length is 70",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/,
        message: "Project name should contain only alphabates",
      },
    ],
    projectOwner: [
      {
        validation: "notEmpty",
        message: "Project owner cannot be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Project Owner min length is 2",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Project Owner max length is 70",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/,
        message: "Project Owner should contain only alphabates",
      },
    ],
    searchByOwner: [
      {
        validation: "minLength",
        minLength: 2,
        message: "Search min length is 2",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Search max length is 70",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/,
        message: "Search should contain only alphabates",
      },
    ],
  },
  signValidation: {
    email: [
      {
        validation: "notEmpty",
        message: "Email is required",
      },
      {
        validation: "email",
        message: "Email is not valid",
      },
    ],
    password: [
      {
        validation: "notEmpty",
        message: "Password is required",
      },
    ],
  },
  signUpValidation: {
    firstName: [
      {
        validation: "notEmpty",
        message: "First name is required",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "First name min length is 2",
      },
      {
        validation: "maxLength",
        maxLength: 20,
        message: "First name max length is 20",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/,
        message: "First name should contain only alphabates",
      },
    ],
    lastName: [
      {
        validation: "notEmpty",
        message: "Last name is required",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Last name min length is 2",
      },
      {
        validation: "maxLength",
        maxLength: 20,
        message: "Last name max length is 20",
      },
      {
        validation: "regex",
        regex: /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/,
        message: "Last name should contain only alphabates",
      },
    ],
    email: [
      {
        validation: "notEmpty",
        message: "Email is required",
      },
      {
        validation: "email",
        message: "Email is not valid",
      },
    ],
    password: [
      {
        validation: "notEmpty",
        message: "Password is required",
      },
      // {
      //   validation: "regex",
      //   regex:
      //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/,
      //   message:
      //     "! Min 8 & Max 32 char, 1 upper & 1 lower case & 1 number & special char",
      // },
      // {
      //   validation: "minLength",
      //   minLength: 8,
      //   message:
      //     "! Min 8 & Max 32 char, 1 upper & 1 lower case & 1 number & special char",
      // },
      // {
      //   validation: "maxLength",
      //   maxLength: 32,
      //   message:
      //     "! Min 8 & Max 32 char, 1 upper & 1 lower case & 1 number & special char",
      // },
    ],
    confirmPassword: [
      {
        validation: "notEmpty",
        message: "Confirm password is required",
      },
    ],
  },
  createProjectValidations: {
    projectObjective: [
      {
        validation: "notEmpty",
        message: "Project Objective should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 5,
        message: "Project Objective minimum length is 5.",
      },
      {
        validation: "maxLength",
        maxLength: 200,
        message: "Project Objective maximum length is 200.",
      },
    ],
    projectTitle: [
      {
        validation: "notEmpty",
        message: "Project title should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 5,
        message: "Project title minimum length is 5.",
      },
      {
        validation: "maxLength",
        maxLength: 200,
        message: "Project title maximum length is 200.",
      },
    ],
    projectGoal: [
      {
        validation: "notEmpty",
        message: "Project goal should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 5,
        message: "Project goal minimum length is 5.",
      },
      {
        validation: "maxLength",
        maxLength: 250,
        message: "Project goal maximum length is 250.",
      },
    ],
    projectManager: [
      {
        validation: "notEmpty",
        message: "Project manager should not be empty.",
      },
    ],
    projectTheme: [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 project theme.",
      },
    ],
    projectBudget: [
      {
        validation: "notEmpty",
        message: "Project budget should not be empty.",
      },
      // {
      //   validation: "regex",
      //   regex: /^(\d{1,12}|\d{0,12}\.\d{1,2})$/,
      //   message: "Project Budget not valid.",
      // }
    ],
    projectFinanceOfficer: [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 project finance officer.",
      },
    ],
    projectStaff: [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 project staff.",
      },
    ],
    region: [
      {
        validation: "notEmpty",
        message: "Select a project region.",
      },
    ],
    country: [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 country.",
      },
    ],
    projectDocument: [
      {
        validation: "notEmpty",
        message: "Project document should not be empty.",
      },
      {
        validation: "URL",
        message: "Project document is not valid.",
      },
    ],
    year: [
      {
        validation: "notEmpty",
        message: "Select year for Budget Breakdown.",
      },
    ],
    budgetAllocated: [
      {
        validation: "notEmpty",
        message: "Allocated Budget should not be empty.",
      },
      // {
      //   validation: "regex",
      //   regex: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
      //   message: "Allocated Budget not valid.",
      // }
    ],
    projectStartDate:[
      {
        validation: "notEmpty",
        message: "Start date should not be empty.",
      },
    ],
    projectEndDate: [
      {
        validation: "notEmpty",
        message: "End date should not be empty.",
      },
    ]
  },
  addActivityValidations: {
    title: [
      {
        validation: "notEmpty",
        message: "Title password is required",
      },
    ],
    description: [
      {
        validation: "notEmpty",
        message: "Title password is required",
      },
    ],
    unit: [
      {
        validation: "notEmpty",
        message: "Title password is required",
      },
    ],
    code: [
      {
        validation: "notEmpty",
        message: "Title password is required",
      },
    ],
    leader: [
      {
        validation: "notEmpty",
        message: "Title password is required",
      },
    ],
  },
  workPlan: {
    mainOutput:[
      {
        validation: "notEmpty",
        message: "Main output should not be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Main output minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 600,
        message: "Main output maximum length is 600.",
      },
    ],
    dueDate: [
      {
        validation: "notEmpty",
        message: "Due should not be empty",
      },
    ],
    comment: [
      {
        validation: "maxLength",
        maxLength: 250,
        message: "Comment maximum length is 250.",
      },
    ],
    rationale: [
      {
        validation: "notEmpty",
        message: "Rationale should not be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Rationale minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 2000,
        message: "Rationale maximum length is 2000.",
      },
    ]
  },
  addTask:{
    nameOfStaffMember: [
      {
        validation: "notEmpty",
        message: "Staff Name should not be empty",
      },
    ],
    taskName: [
      {
        validation: "notEmpty",
        message: "Task Description should not be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Task Description minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 600,
        message: "Task Description maximum length is 600.",
      },
    ],
    description: [
      {
        validation: "maxLength",
        maxLength: 600,
        message: "Remark maximum length is 600.",
      },
    ],
    averageSalary: [
      // {
      //   validation: "regex",
      //   regex: /^[0-9]*$/,
      //   message: "Total Days should be a number.",
      // },
    ],
    totalDays: [
      // {
      //   validation: "regex",
      //   regex: /^[0-9]*$/,
      //   message: "Total Days should be a number.",
      // },
    ],
    travelCost: [
      // {
      //   validation: "notEmpty",
      //   message: "Travel cost should not be empty.",
      // },
      // {
      //   validation: "regex",
      //   regex: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
      //   message: "Travel cost is not valid.",
      // }
    ],
    otherCost: [
      // {
      //   validation: "notEmpty",
      //   message: "Other cost should not be empty.",
      // },
      // {
      //   validation: "regex",
      //   regex: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/,
      //   message: "Other cost is not valid.",
      // }
    ],
    totalDaysType: [
      {
        validation: "notEmpty",
        message: "Total days type should not be empty.",
      },
    ],
    travelCostType: [
      {
        validation: "notEmpty",
        message: "Travel cost type should not be empty.",
      },
    ],
    otherCostType: [
      {
        validation: "notEmpty",
        message: "Other cost type should not be empty.",
      },
    ],
    totalDaysProjectId: [
      {
        validation: "notEmpty",
        message: "Project name should not be empty.",
      },
    ],
    travelCostProjectId: [
      {
        validation: "notEmpty",
        message: "Project name should not be empty.",
      },
    ],
    otherCostProjectId: [
      {
        validation: "notEmpty",
        message: "Project name should not be empty.",
      },
    ],
    otherCostAccount: [
      {
        validation: "notEmpty",
        message: "Account for other cost should not be empty.",
      },
    ]
  },
  addEmployee: {
    employee : [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 Employee.",
      },
    ],
    userGroup: [
      {
        validation: "isNonEmptyArray",
        message: "Select at least 1 User Group.",
      },
    ]
  },
  addUserGroup: {
    userGroupTitle : [
      {
        validation: "notEmpty",
        message: "User Group Name should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "User Group Name minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "User Group Name maximum length is 70.",
      },
    ],
    userGroupDescription: [
      {
        validation: "notEmpty",
        message: "User Group Description should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 5,
        message: "User Group Description minimum length is 5.",
      },
      {
        validation: "maxLength",
        maxLength: 250,
        message: "User Group Description maximum length is 250.",
      },
    ]
  },
  mainOutPutUnlocked: {
    mainOutput:[
      {
        validation: "notEmpty",
        message: "Main output should not be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Main output minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Main output maximum length is 70.",
      },
    ],
    dueDate: [
      {
        validation: "notEmpty",
        message: "Due should not be empty",
      },
    ],

  },
  addEntry: {
    selectActivity: [
      {
        validation: "notEmpty",
        message: "Activity should not be empty.",
      },
    ],
    comment: [
      {
        validation: "notEmpty",
        message: "Comment should not be empty.",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Comment minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 250,
        message: "Comment maximum length is 250.",
      },
    ],
    hoursWorked: [
      {
        validation: "notEmpty",
        message: "Hours Worked should not be empty.",
      },
    ],
    projectId: [
      {
          validation: "notEmpty",
          message: "Project should not be empty.",
      }
    ]

  },

  addHoliday: {
    holidayDay: [
      {
        validation: "notEmpty",
        message: "Day should not be empty",
      },
    ],
    title: [
      {
        validation: "notEmpty",
        message: "Holiday title should not be empty",
      },
      {
        validation: "minLength",
        minLength: 2,
        message: "Holiday title minimum length is 2.",
      },
      {
        validation: "maxLength",
        maxLength: 70,
        message: "Holiday title maximum length is 70.",
      },
    ]
  }


};
