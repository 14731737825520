import React from "react";
import {removeAccessToken} from "../../../shared/request/token";
import {auth as msAuth} from "../../../config/auth";

const Support = () => {
  const logout = () => {
      removeAccessToken();
      msAuth.logout();
  };

  return (
    <>
      <div className={"text-center"}>
          <div className={"card bg-white pt-3"}>
            <p>
              Your account is not registered to use the system, please contact your
              admin.
            </p>
          </div>
        <button className={"ad-logout-btn"} onClick={logout}>
          Logout
        </button>
      </div>
    </>
  );
};

export default Support;
