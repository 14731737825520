import React, {Component, useEffect} from "react";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import { removeAccessToken } from "../../../../shared/request/token"
import { auth as msAuth } from "../../../../config/auth"
import * as auth0 from "../_redux/authRedux";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function Logout(props) {
  const history = useHistory();
  useEffect(async () => {
    removeAccessToken();
    // msAuth.logout();
    await props.setUser({});
    // setTimeout(() => {
    //   history.push("/auth/login");
    // }, 1000);
    // window.location.reload();
    // this.props.history.push("/auth/login");
  }, );

return (
    <> <LayoutSplashScreen /> </>
  )
}

export default connect(null, auth0.actions)(Logout);
