import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "../../../../shared/SnackbarComponent";
import { authEnum } from "../../../../shared/enum/auth.enum";
import { useHistory } from "react-router-dom";
import { auth } from "../../../../config/auth";
import MSLOGO from "./microsoft.png";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import validationService from "../../../../shared/validationService";
import validations from "../../../../shared/validations";
import * as auth0 from "../_redux/authRedux";
import { newLogin , getCurrentUser} from "../../../../api/auth.api";

function Login(props) {
  const history = useHistory();
  localStorage.removeItem(authEnum.ACCESS_TOKEN);
  localStorage.removeItem(authEnum.REFRESH_TOKEN);
  localStorage.removeItem(authEnum.EXPIRE_AT);
  const [loginDetails, setLoginDetails] = React.useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = React.useState({
    email: {
      errorMessage: "",
    },
    password: {
      errorMessage: "",
    },

  });

  const loginAD = (e) => {
    e.preventDefault();
    auth.loginRedirect();
  };


  const handleCustomValidation = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: validations.signValidation[name],
      },
    ];

    validationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMessage[name].errorMessage =
          validationErrMsgs[0].validations[0].message;
        setErrorMessage({ ...errorMessage });
      } else {
        errorMessage[name].errorMessage = "";
        setErrorMessage({ ...errorMessage });
      }
    });
    setLoginDetails({
      ...loginDetails,
      [name]: value
    })

    return errorLength;
  };

  const finalValidation = () => {
    let valid = true;
    let error = 0;
    error = error + handleCustomValidation("email", loginDetails.email)
    error = error + handleCustomValidation("password", loginDetails.password)
    if (error != 0) {
      valid = false;
    }
    return valid;
  }

  const handleLogin = async () => {
    let response1 = null;
    if (finalValidation()) {
      // history.push("/logout")
      // props.setUser({name: "Admin", email: loginDetails.email, password: loginDetails.password});
      await newLogin(
        {email: loginDetails.email,
        password: loginDetails.password}
      ).then(async(response) => {
        console.log("Login response",response);
        response1 = response;
        // await props.setUser({...response});
        if(response1 !== null)
        {
          const user = await getCurrentUser();
          console.log(">>>>>>>>???", user)
          await props.setUser({...user})
        }
      }).catch((error) => {
        console.log("Login error",error);
      })
    }
  }

  return (
    <form>
      <div className={"d-flex justify-content-center"}>
        {/* <button className={"ad-login-btn"} onClick={loginAD}>
          <img src={MSLOGO} alt={'microsoft login'} /> Login with Microsoft
        </button> */}
        <div style={{
          background: "#F0FFF0",
          width: "350px",
          borderRadius: "5px",
          boxShadow: "0px 0px 6px #00000029",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            style={{ width: "100%" }}
            name="email"
            error={errorMessage && errorMessage.email && errorMessage.email.errorMessage.length > 0 }
            onChange={(e) => { handleCustomValidation(e.target.name, e.target.value) }} />

          <div style={{ color: "red", textAlign: "left", width: "98%", paddingLeft: "2%", marginBottom: "0px" }}>
            {errorMessage && errorMessage.email && errorMessage.email.errorMessage.length > 0 ? errorMessage.email.errorMessage : <span>&nbsp;</span>}
          </div>

          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            style={{ width: "100%", marginTop: "15px" }}
            name="password"
            onChange={(e) => { handleCustomValidation(e.target.name, e.target.value) }}
            error={errorMessage && errorMessage.password && errorMessage.password.errorMessage.length > 0}
          />

          <p style={{ color: "red", textAlign: "left", width: "98%", paddingLeft: "2%" , marginBottom: "0px"}}>
            {errorMessage && errorMessage.password && errorMessage.password.errorMessage.length > 0 ? errorMessage.password.errorMessage : <span>&nbsp;</span>}
          </p>
          <Button variant="contained" color="primary" style={{ width: "100%", marginTop: "15px" }}
            onClick={handleLogin}>
            Login
          </Button>
        </div>
      </div>
    </form>
  );
}

export default connect(null, auth0.actions)(withSnackbar(Login));
