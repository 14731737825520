/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";

import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import LOGO from "../../../../shared/images/GWP LOGO.svg";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Support from "../../../pages/Support";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function AuthPage() {
  // for snaker
  const [responseMessage, responseMessageFun] = React.useState({
    data: {
      message: "",
      status: null,
      severity: undefined,
    },
    alertRes: false,
  });

  function handleCloseOfAlert() {
    responseMessageFun({
      data: null,
      alertRes: false,
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
      className={"login-page-main"}
    >
      <div className={"d-flex justify-content-center"}>
        <img className={"login-app-logo"} src={LOGO} alt={"GWPO-LOGO"} />
      </div>
      <div
        style={{
          // background: "white",
          width: "30vw",
          padding: "25px 0px",
          // boxShadow: "1px 0.5px 5px 0px #e0e0e0",
          borderRadius: "10px",
        }}
      >
        <Switch>
          <ContentRoute path="/auth/login" component={Login} />
          <ContentRoute path="/auth/support" component={Support} />
          <Redirect exact from={"/*"} to={"/auth/login"} />
        </Switch>

        {responseMessage.data && responseMessage.data.status && (
          <Snackbar
            onClose={handleCloseOfAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            open={responseMessage.alertRes}
          >
            <Alert
              onClose={handleCloseOfAlert}
              severity={responseMessage.data.severity}
            >
              {responseMessage.data.message}
            </Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
}
