import { request } from "../shared/request";

const API_ROOT = process.env.REACT_APP_API_URL;

const LOGIN = "/auth/signin";
const REGISTER = "/auth/signup";
const USER = "/users/getCurrentUser";
const NEW_LOGIN = "/auth/login"

export async function login(credentials) {
  return request({
    url: API_ROOT + LOGIN,
    method: "POST",
    body: credentials,
  });
}

export async function newLogin(credentials) {
  return request({
    url: API_ROOT + NEW_LOGIN,
    method: "POST",
    body: credentials,
  });
}

export async function register(credentials) {
  return request({
    url: API_ROOT + REGISTER,
    method: "POST",
    body: credentials,
  });
}

export async function getCurrentUser() {
  return request({
    url: API_ROOT + USER,
    method: "GET",
  });
}
