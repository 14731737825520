import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";

const ConfigureActivities = lazy(() => import("./pages/ConfigureActivities"));
const WorkPlanTemplate = lazy(() => import("./pages/WorkPlanTemplate"));
const Projects = lazy(() => import("./pages/Projects"));
const WorkPlan = lazy(() => import("./pages/WorkPlan"));
const Tasks = lazy(() => import("./pages/Tasks"));
const MainOutput = lazy(() => import("./pages/MainOutput"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const UserManagement = lazy(() => import("./pages/UserManagement"));
const Salary = lazy(() => import("./pages/Salary"));
const Programs = lazy(() => import("./pages/Programs"));
const TimeSheets = lazy(() => import("./pages/TimeSheets"));
const HolidayCalendar = lazy(() => import("./pages/HolidayCalendar"))

export default function BasePage() {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/projects" component={Projects} />
        <ContentRoute path="/activities" component={ConfigureActivities} />
        <ContentRoute path="/workplanTemplate" component={WorkPlanTemplate} />
        <ContentRoute path="/workPlan" component={WorkPlan} />
        <ContentRoute path="/tasks" component={Tasks} />
        <ContentRoute path="/mainOutput" component={MainOutput} />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/userManagement" component={UserManagement} />
        <ContentRoute path="/salary" component={Salary} />
        <ContentRoute path="/programs" component={Programs} />
        <ContentRoute path="/timeSheets" component={TimeSheets} />
        <ContentRoute path="/holidayCalendar" component={HolidayCalendar} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
