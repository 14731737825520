/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#757575",
  },
  tooltip: {
    backgroundColor: "#757575",
    fontSize: 12,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export function UserProfileDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();
  const tooltipClasses = useStylesBootstrap()

  const { user } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {

    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  }

  return (
    <>
      {/*<Dropdown drop="down" alignRight>*/}
      {/*  <Dropdown.Toggle*/}
      {/*    as={DropdownTopbarItemToggler}*/}
      {/*    id="dropdown-toggle-user-profile"*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      className={*/}
      {/*        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"*/}
      {/*      }*/}
      {/*    >*/}
      {/*      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">*/}
      {/*        Hi,*/}
      {/*      </span>{" "}*/}
      {/*      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">*/}
      {/*        {user.firstName} {user.lastName}*/}
      {/*      </span>*/}
      {/*      <span className="symbol symbol-35 symbol-light-success">*/}
      {/*        <span className="symbol-label font-size-h5 font-weight-bold">*/}
      {/*          {user.firstName[0]}*/}
      {/*        </span>*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*  </Dropdown.Toggle>*/}
      {/*  <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">*/}
      {/*    <>*/}
      {/*      /!** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' *!/*/}
      {/*      {layoutProps.light && (*/}
      {/*        <>*/}
      {/*          <div className="d-flex align-items-center p-8 rounded-top">*/}
      {/*            <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">*/}
      {/*              <img*/}
      {/*                src={toAbsoluteUrl("/media/users/300_21.jpg")}*/}
      {/*                alt=""*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*            <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">*/}
      {/*              {user.firstname} {user.lastname}*/}
      {/*            </div>*/}
      {/*            <span className="label label-light-success label-lg font-weight-bold label-inline">*/}
      {/*              3 messages*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*          <div className="separator separator-solid"></div>*/}
      {/*        </>*/}
      {/*      )}*/}

      {/*      {!layoutProps.light && (*/}
      {/*        <div*/}
      {/*          className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"*/}
      {/*          style={{*/}
      {/*            backgroundImage: `url(${toAbsoluteUrl(*/}
      {/*              "/media/misc/bg-1.jpg"*/}
      {/*            )})`,*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div className="symbol bg-white-o-15 mr-3">*/}
      {/*            <span className="symbol-label text-success font-weight-bold font-size-h4">*/}
      {/*              {user.firstName[0]}*/}
      {/*            </span>*/}
      {/*            /!*<img alt="Pic" className="hidden" src={user.pic} />*!/*/}
      {/*          </div>*/}
      {/*          <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">*/}
      {/*            {user.name}*/}
      {/*          </div>*/}
      {/*          <span className="label label-success label-lg font-weight-bold label-inline">*/}
      {/*            3 messages*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </>*/}

      {/*    <div className="navi navi-spacer-x-0 pt-5">*/}
      {/*      <div className="navi-separator mt-3"></div>*/}

      {/*      <div className="navi-footer  px-8 py-5">*/}
      {/*        <Link*/}
      {/*          to="/logout"*/}
      {/*          className="btn btn-light-primary font-weight-bold"*/}
      {/*        >*/}
      {/*          Sign Out*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Dropdown.Menu>*/}
      {/*</Dropdown>*/}
      <Popper
        open={open}
        disablePortal
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{
          backgroundColor: "#fff",
          width: "250px",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
        <div>
          <div className="navi navi-spacer-x-0 pt-5">
            {/*<div className="navi-separator mt-3"></div>*/}
            <div className="px-8 py-1 d-flex align-items-center justify-content-start">
              <span className="mr-3"><AccountCircleIcon size={18} style={{color: "#00AEEF"}}/></span>
              <div  style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",}}>
                {/* {user.fullName} */}
               Admin
                </div>
            </div>
            <div className="px-8 py-1 d-flex align-items-center justify-content-start">
              <span className="mr-3"><MailOutlineOutlinedIcon size={18} style={{color: "#00AEEF"}}/></span>
              <Tooltip title={"Hi" } arrow classes={tooltipClasses} >
                <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",}}>
                {/* {user.fullName} */}
                Admin
                </div>
               </Tooltip>
            </div>
            <div className="navi-footer  px-8 py-5">
              <Link
                to="/logout"
                className="btn btn-light-primary font-weight-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
        </ClickAwayListener>
      </Popper>
      <div
        onBlur={handleClose}
        onClick={handleClick("bottom-end")}
        className={"mt-3"}
      >
        <div className="topbar-item">
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span
              className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
              style={{ textTransform: "none" }}
            >
              {/* {user.fullName} */}
              Admin
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {/* {user.fullName[0]} */}
                A
              </span>
            </span>
            <ChevronDownIcon className={"ml-2"} size={20} />
          </div>
        </div>
      </div>
    </>
  );
}
