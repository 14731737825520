import { responseEnum } from "../enum/response.enum";
import axios from "axios";
import { getAccessToken, getTokenExpire, setAccessToken, getRefreshToken, getIdToken } from "./token";
import jwt_decode from "jwt-decode";
import {getToken, renewToken} from "../../config/auth"

export async function setupAxios(axios) {
  axios.interceptors.request.use(
    async (config) => {
      // console.log("in request")
      // let accessToken = getIdToken();
      // if(config.url.includes("graph.microsoft.com")) {
      //   accessToken = getAccessToken();
      // } else {
      //   accessToken = getIdToken();
      // }
      // const expireAt = getTokenExpire();
      // console.log("EXP :: ", new Date(expireAt), "::: current :: ", new Date())
      // if (new Date(expireAt) < new Date()) {
      //   if(!config.url.includes("/refresh-token")) {
      //     console.log("in if >>>>");
      //     window.location.href = "/auth/login";
      //     const newToken = await renewToken();
      //     console.log("NEW TOKEN ::: ", newToken)
      //     accessToken = newToken
      //   }
      // }
      // // setting updated token
      // if (accessToken && !config.url.includes("/refresh-token")) {
      //   config.headers.Authorization = `Bearer ${accessToken}`;
      // }
      // config.headers.clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // config.headers.timezoneoffset = new Date().getTimezoneOffset();
      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      console.log("in response")
      if (response.status === 200 || response.status === 201 || response.statusCode === 422) {
        return response.data || [];
      }
      throw response;
    },
    (error) => {
      console.log('>>>>error exist = ', error);

      let response = {
        status: false,
        message: responseEnum.SOMETHING_WRONG,
      }; 
      if (error && error.response) {
        if (error && error.response && error.response.status === 401) {
          
          if (window.location.pathname.endsWith("/auth/login")) {
            console.log('else1')
            response = error.response.data ? error.response.data : response;
            throw response;
          } else {
            console.log('else')
            window.location.href = "/auth/login";
          }
        } else if (error && error.response && error.response.status === 403) {
          // handle403Page(appConfigs.AUTH_BASE_URL);
          // window.location.reload(true);
        } else if (error && error.response && error.response.status === 503) {
          // handle403Page(appConfigs.AUTH_BASE_URL);
          // window.location.reload(true);
          window.location.href = "/503";
        } else if (error.response.data && error.response.data.message) {
          response = error.response.data ? error.response.data : response;
          throw response;
        } else {
          response = error.response.data ? error.response.data : response;
          throw response;
        }
      } else if (
        error &&
        error.toString() &&
        error.toString().includes("Network Error")
      ) {
        // window.location.href = process.env.PUBLIC_URL + "/";
      } else if (
        error &&
        error.toString() &&
        error.toString().includes("Cancel")
      ) {
        response = error;
        throw response;
      } else {
        throw response;
      }
    }
  );
}


export const requestExcel = (options) => {
  const config = {
    headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    url: options['url'],
    method: options['method'],
    responseType: 'blob',
  };

  if (options['body']) {
    config['data'] = options['body'];
  }
  if (options['params']) {
    config['params'] = options['params'];
  }

  if (navigator.onLine) {
    return axios.request(config);
  } else {
    let response;
    response = {
      status: false,
      message: responseEnum.INTERNET_DISCONNECTED,
    };
    return response;
  }
};

export const requestExport = (options) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    url: options["url"],
    method: options["method"],
  };

  if (options["body"]) {
    config["data"] = options["body"];
  }
  if (options["params"]) {
    config["params"] = options["params"];
  }

  if (navigator.onLine) {
    return axios.request(config);
  } else {
    let response;
    response = {
      status: false,
      message: responseEnum.INTERNET_DISCONNECTED,
    };
    return response;
  }
};

export const request = (options) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    url: options["url"],
    method: options["method"],

  };

  if (options["body"]) {
    config["data"] = options["body"];
  }
  if (options["params"]) {
    config["params"] = options["params"];
  }

  // if(options["url"].includes("/auth/login"))
  // {
  //   config["withCredentials"] = false;
  // }
  // else
  // {
    config["withCredentials"] = true;
  // }

  console.log(">>>>>>>>>config", config);
  if (navigator.onLine) {
    return axios.request(config);
  } else {
    let response;
    response = {
      status: false,
      message: responseEnum.INTERNET_DISCONNECTED,
    };
    return response;
  }
};

const refreshToken = async () => {
  return await renewToken()
}
