import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth0 from "./authRedux";
import { getCurrentUser } from "../../../../api/auth.api";
import { setIdToken } from "../../../../shared/request/token";
import { auth, getToken } from "../../../../config/auth";
import { useHistory } from "react-router-dom";

function AuthInit(props) {
  const history = useHistory();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  // We should request user by authToken before rendering the application
  useEffect(() => {
    console.log("in Auth init before login")
    const requestUser = async () => {
        console.log("AAA");
          try {
            const user = await getCurrentUser();
            if (user) {
              props.setUser(user);
              setShowSplashScreen(false);
            }
          } catch (e) {
            if (e.statusCode === 404) {
              history.push("/auth/support");
              setShowSplashScreen(false);
            }
          } finally {
            setShowSplashScreen(false);
          }
      } 
      // else {
      //   console.log("DDD");
      //   setShowSplashScreen(false);
      //   // props.setUser({name: "rahul"});
      //   console.log("User Auth in", user)
      //   if(( user == undefined || user == null) || Object.keys(user).length === 0)
      //   {
      //     console.log("In if") 
      //     const validUser = await getCurrentUser();
      //     if(validUser)
      //     {
      //       props.setUser(validUser);
      //       // history.push("/dasboard");
      //     }
      //     else
      //     {
      //       history.push("/auth/login");
      //     }
      //   }
      //   else
      //   {
      //     console.log("In else")  
      //     history.push("/dasboard");
      //   }
      // }

    requestUser();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {console.log("showSplashScreen status :::: ", showSplashScreen)}
      {showSplashScreen ? <><LayoutSplashScreen /></> : <>{props.children}</>}
    </>
  );
}

export default connect(null, auth0.actions)(AuthInit);
