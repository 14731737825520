import { authEnum } from "../enum/auth.enum"

export function setAccessToken(access_token) {
    // return authorization header with jwt token
    localStorage.setItem(authEnum.ACCESS_TOKEN, access_token)
}
export function setIdToken(token) {
    // return authorization header with jwt token
    localStorage.setItem(authEnum.ID_TOKEN, token)
}

export function setRefreshToken(refresh_token) {
    // return authorization header with jwt token
    localStorage.setItem(authEnum.REFRESH_TOKEN, refresh_token)
}

export function setTokenExpire(time) {
    // return authorization header with jwt token
    localStorage.setItem(authEnum.EXPIRE_AT, time)
}

export function getTokenExpire() {
    // return authorization header with jwt token
    return localStorage.getItem(authEnum.EXPIRE_AT)
}

export function removeAccessToken() {
    // return authorization header with jwt token
    localStorage.removeItem(authEnum.ACCESS_TOKEN)
    localStorage.removeItem(authEnum.REFRESH_TOKEN)
}

export function getAccessToken() {
    // return authorization header with jwt token
    return localStorage.getItem(authEnum.ACCESS_TOKEN)
}

export function getIdToken() {
    // return authorization header with jwt token
    return localStorage.getItem(authEnum.ID_TOKEN)
}

export function getRefreshToken() {
    // return authorization header with jwt token
    return localStorage.getItem(authEnum.REFRESH_TOKEN)
}
