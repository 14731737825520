export const modulePermissionEnum = {
  WORKPLAN: "workplan",
  WORKPLAN_TEMPLATE: "workplanTemplate",
  DASHBOARD: "dashboard",
  ACTIVITY: "activity",
  MAINOUTPUT: "mainOutput",
  TASK: "task",
  USER_MANAGEMENT: "userManagement",
  PROJECT: "project",
  SALARY: "salary",
  PROGRAM: "program",
  TIMESHEET: "timesheet",
  HOLIDAYCALENDAR: "holidayCalendar"
};
