import { UserAgentApplication } from "msal";
import {
  setAccessToken,
  setIdToken,
  setTokenExpire,
} from "../shared/request/token";

const clientId = process.env.REACT_APP_AAD_CLIENT_ID;
const tenantId = process.env.REACT_APP_AAD_TENANT_ID;
const redirectUri = window.location.origin;

const config = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    postLoginRedirectUri: redirectUri,
  },
};

const tokenConfig = {
  scopes: ["https://graph.microsoft.com/.default"],
};

export const auth = new UserAgentApplication(config);

auth.handleRedirectCallback((error) => {
  if (error) {
    console.error(error);
  }
});

export const getToken = () =>
  auth
    .acquireTokenSilent(tokenConfig)
    .catch((err) => {
      return auth.acquireTokenRedirect(tokenConfig);
    })
    .then((res) => {
      console.log("RES TOKEN ", res);
      setAccessToken(res.accessToken);
      setIdToken(res?.idToken?.rawIdToken);
      setTokenExpire(res.expiresOn);
      return res?.idToken?.rawIdToken;
      // return accessToken
    });

export const renewToken = () =>
  auth
    .acquireTokenSilent(tokenConfig)
    .catch((err) => {
      return auth.acquireTokenRedirect(tokenConfig);
    })
    .then((res) => {
      setAccessToken(res.accessToken);
      setIdToken(res?.idToken?.rawIdToken);
      setTokenExpire(res.expiresOn);
      return res.accessToken;
    });
