/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewDashboardOutlineIcon from "mdi-react/ViewDashboardOutlineIcon";
import ClipboardOutlineIcon from "mdi-react/ClipboardOutlineIcon";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import BriefcaseOutlineIcon from "mdi-react/BriefcaseOutlineIcon";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import EuroIcon from '@material-ui/icons/Euro';
import { useSelector, shallowEqual } from "react-redux";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { modulePermissionEnum } from "../../../../../shared/enum/modulepermission.enum";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {user.modulePermissions.indexOf(modulePermissionEnum.DASHBOARD) ===
          -1 && (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <ViewDashboardOutlineIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
         )} 
        {user.modulePermissions.indexOf(modulePermissionEnum.WORKPLAN) ===
          -1 && (
          <li
            className={`menu-item ${getMenuItemActive("/workPlan", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/workPlan">
              <span className="svg-icon menu-icon">
                <ClipboardTextOutlineIcon
                  style={{ fill: "white" }}
                ></ClipboardTextOutlineIcon>
              </span>
              <span className="menu-text">Work Plan</span>
            </NavLink>
          </li>
         )} 
        {user.modulePermissions.indexOf(modulePermissionEnum.TASK) === -1 && 
        (
          <li
            className={`menu-item ${getMenuItemActive("/tasks", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tasks">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <PlaylistAddCheckIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Tasks</span>
            </NavLink>
          </li>
         )} 
         {user.modulePermissions.indexOf(modulePermissionEnum.MAINOUTPUT) ===
          -1 && ( 
          <li
            className={`menu-item ${getMenuItemActive("/mainOutput", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/mainOutput">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <FormatListNumberedIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Main Outputs</span>
            </NavLink>
          </li>
         )} 
        {user.modulePermissions.indexOf(
          modulePermissionEnum.WORKPLAN_TEMPLATE
        ) === -1 && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/workplanTemplate",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/workplanTemplate">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <ClipboardOutlineIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Work Plan Template</span>
            </NavLink>
          </li>
         )} 
         {user.modulePermissions.indexOf(modulePermissionEnum.PROJECT) ===
          -1 && ( 
          <li
            className={`menu-item ${getMenuItemActive("/projects", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/projects">
              <span className="svg-icon menu-icon">
                <DnsOutlinedIcon
                  style={{ fill: "white" }}
                ></DnsOutlinedIcon>
              </span>
              <span className="menu-text">Projects</span>
            </NavLink>
          </li>
         )}
        {user.modulePermissions.indexOf(
          modulePermissionEnum.USER_MANAGEMENT
        ) === -1 && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/userManagement",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/userManagement">
              <span className="svg-icon menu-icon">
                <AccountBoxOutlinedIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">User Management</span>
            </NavLink>
          </li>
        )}
         {user.modulePermissions.indexOf(modulePermissionEnum.ACTIVITY) ===
          -1 && (
          <li
            className={`menu-item ${getMenuItemActive("/activities", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/activities">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <ContentCopyIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Configure Activities</span>
            </NavLink>
          </li>
        )} 
         {user.modulePermissions.indexOf(modulePermissionEnum.SALARY) ===
        -1 && (
          <li
            className={`menu-item ${getMenuItemActive("/salary", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/salary">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <EuroIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Salary</span>
            </NavLink>
          </li>
        )}
        {user.modulePermissions.indexOf(modulePermissionEnum.PROGRAM) ===
        -1 && (
          <li
            className={`menu-item ${getMenuItemActive("/programs", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/programs">
              <span className="svg-icon menu-icon">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
                <WorkOutlineIcon style={{ fill: "white" }} />
              </span>
              <span className="menu-text">Programs</span>
            </NavLink>
          </li>
         )} 
        { user.modulePermissions.indexOf(modulePermissionEnum.TIMESHEET) ===
          -1 && (
          <li
          className={`menu-item ${getMenuItemActive("/timeSheets", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/timeSheets">
            <span className="svg-icon menu-icon">
              {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
              <DateRangeIcon style={{ fill: "white" }}/>
            </span>
            <span className="menu-text">Timesheets</span>
          </NavLink>
        </li>
         )} 
        {/*end::1 Level*/}
        { user.modulePermissions.indexOf(modulePermissionEnum.HOLIDAYCALENDAR) ===
          -1 && (
        <li
          className={`menu-item ${getMenuItemActive("/holidayCalendar", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/holidayCalendar">
            <span className="svg-icon menu-icon">
              {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />*/}
              <InsertInvitationIcon style={{ fill: "white" }}/>
            </span>
            <span className="menu-text">Holiday Calendar</span>
          </NavLink>
        </li>
       )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
